import { ActionType, createAction, props } from "@ngrx/store";
import { TransactionDetails, TransactionStatementPeriodDetails } from "./transaction.types";

export const GET_TRANSACTION_STATEMENT_PERIOD_DETAILS = 'GET_TRANSACTION_STATEMENT_PERIOD_DETAILS';
export const GET_TRANSACTION_STATEMENT_PERIOD_DETAILS_SUCCESS = 'GET_TRANSACTION_STATEMENT_PERIOD_DETAILS_SUCCESS';
export const GET_TRANSACTION_STATEMENT_PERIOD_DETAILS_FAILURE = 'GET_TRANSACTION_STATEMENT_PERIOD_DETAILS_FAILURE';

export const GET_TRANSACTION_DETAILS = 'GET_TRANSACTION_DETAILS';
export const GET_TRANSACTION_DETAILS_SUCCESS = 'GET_TRANSACTION_DETAILS_SUCCESS';
export const GET_TRANSACTION_DETAILS_FAILURE = 'GET_TRANSACTION_DETAILS_FAILURE';

export const SAVE_VALIDATE_STATEMENT_TRANSACTION = 'SAVE_VALIDATE_STATEMENT_TRANSACTION';
export const SAVE_VALIDATE_STATEMENT_TRANSACTION_SUCCESS = 'SAVE_VALIDATE_STATEMENT_TRANSACTION_SUCCESS';
export const SAVE_VALIDATE_STATEMENT_TRANSACTION_FAILURE = 'SAVE_VALIDATE_STATEMENT_TRANSACTION_FAILURE';
export const CLEAR_SAVE_VALIDATE_STATEMENT_TRANSACTION = 'CLEAR_SAVE_VALIDATE_STATEMENT_TRANSACTION';

export const REVALIDATE_SUMMARY = 'REVALIDATE_SUMMARY';
export const REVALIDATE_SUMMARY_SUCCESS = 'REVALIDATE_SUMMARY_SUCCESS';
export const REVALIDATE_SUMMARY_FAILURE = 'REVALIDATE_SUMMARY_FAILURE';

export const PROCESS_TRANSACTION_FLAG = 'PROCESS_TRANSACTION_FLAG';
export const PROCESS_TRANSACTION_FLAG_SUCCESS = 'PROCESS_TRANSACTION_FLAG_SUCCESS';
export const PROCESS_TRANSACTION_FLAG_FAILURE = 'PROCESS_TRANSACTION_FLAG_FAILURE';

export const PROCESS_CATEGORIES = 'PROCESS_CATEGORIES';
export const PROCESS_CATEGORIES_SUCCESS = 'PROCESS_CATEGORIES_SUCCESS';
export const PROCESS_CATEGORIES_FAILURE = 'PROCESS_CATEGORIES_FAILURE';

export const RESET_TRANSACTIONSFLAG_INFO = 'RESET_TRANSACTIONSFLAG_INFO';
export const RESET_TRANSACTIONSCATEGORY_INFO = 'RESET_TRANSACTIONSCATEGORY_INFO';

export const ResetTransactionsFlagInfo = createAction(RESET_TRANSACTIONSFLAG_INFO);
export const ResetTransactionsCategoryInfo = createAction(RESET_TRANSACTIONSCATEGORY_INFO);


export const GetTransactionStatementPeriodDetails = createAction(
    GET_TRANSACTION_STATEMENT_PERIOD_DETAILS,
    props<{ loanId: string }>()
);

export const GetTransactionStatementPeriodDetailsSuccess = createAction(
    GET_TRANSACTION_STATEMENT_PERIOD_DETAILS_SUCCESS,
    props<{ data: TransactionStatementPeriodDetails[] }>()
);

export const GetTransactionStatementPeriodDetailsFailure = createAction(
    GET_TRANSACTION_STATEMENT_PERIOD_DETAILS_FAILURE,
    props<{ errors?: string[], message: string }>()
);

export const GetTransactionDetails = createAction(
    GET_TRANSACTION_DETAILS,
    props<{ loanId: string; summaryId: string }>()
);

export const GetTransactionDetailsSuccess = createAction(
    GET_TRANSACTION_DETAILS_SUCCESS,
    props<{ data: TransactionDetails[] }>()
);

export const GetTransactionDetailsFailure = createAction(
    GET_TRANSACTION_DETAILS_FAILURE,
    props<{ errors?: string[], message: string }>()
);

export const SaveValidateStatementTransaction = createAction(
    SAVE_VALIDATE_STATEMENT_TRANSACTION,
    props<{ data: TransactionDetails[], isValidated: boolean, loanId: string }>()
);

export const SaveValidateStatementTransactionSuccess = createAction(
    SAVE_VALIDATE_STATEMENT_TRANSACTION_SUCCESS,
    props<{ data: TransactionDetails[] }>()
);

export const SaveValidateStatementTransactionFailure = createAction(
    SAVE_VALIDATE_STATEMENT_TRANSACTION_FAILURE,
    props<{ errors?: string[], message: string }>()
);

export const ClearSaveValidateStatementTransaction = createAction(
    CLEAR_SAVE_VALIDATE_STATEMENT_TRANSACTION
);

export const ReValidateSummary = createAction(
    REVALIDATE_SUMMARY,
    props<{ statementId: string; isValidate: boolean }>()
);

export const ReValidateSummarySuccess = createAction(
    REVALIDATE_SUMMARY_SUCCESS,
    props<{ message: string }>()
);

export const ReValidateSummaryFailure = createAction(
    REVALIDATE_SUMMARY_FAILURE,
    props<{ errors?: string[], message: string }>()
);

export const ProcessTransactionFlag = createAction(
    PROCESS_TRANSACTION_FLAG,
    props<{ loanId: string }>()
);

export const ProcessTransactionFlagSuccess = createAction(
    PROCESS_TRANSACTION_FLAG_SUCCESS,
    props<{ message: string }>()
);

export const ProcessTransactionFlagFailure = createAction(
    PROCESS_TRANSACTION_FLAG_FAILURE,
    props<{ errors?: string[], message: string }>()
);

export const ProcessCategories = createAction(
    PROCESS_CATEGORIES,
    props<{ loanId: string }>()
);

export const ProcessCategoriesSuccess = createAction(
    PROCESS_CATEGORIES_SUCCESS,
    props<{ message: string }>()
);

export const ProcessCategoriesFailure = createAction(
    PROCESS_CATEGORIES_FAILURE,
    props<{ errors?: string[], message: string }>()
);

export type TransactionActions =
    | ActionType<typeof GetTransactionStatementPeriodDetails>
    | ActionType<typeof GetTransactionStatementPeriodDetailsSuccess>
    | ActionType<typeof GetTransactionStatementPeriodDetailsFailure>

    | ActionType<typeof GetTransactionDetails>
    | ActionType<typeof GetTransactionDetailsSuccess>
    | ActionType<typeof GetTransactionDetailsFailure>

    | ActionType<typeof SaveValidateStatementTransactionSuccess>
    | ActionType<typeof SaveValidateStatementTransactionFailure>

    | ActionType<typeof ClearSaveValidateStatementTransaction>

    | ActionType<typeof ReValidateSummary>
    | ActionType<typeof ReValidateSummarySuccess>
    | ActionType<typeof ReValidateSummaryFailure>

    | ActionType<typeof ProcessTransactionFlag>
    | ActionType<typeof ProcessTransactionFlagSuccess>
    | ActionType<typeof ProcessTransactionFlagFailure>

    | ActionType<typeof ProcessCategories>
    | ActionType<typeof ProcessCategoriesSuccess>
    | ActionType<typeof ProcessCategoriesFailure>

    | ActionType<typeof ResetTransactionsFlagInfo>
    | ActionType<typeof ResetTransactionsCategoryInfo>







